<template>
  <div class="Virtual">
    <v-container fluid class="py-0">
      <v-row class="Contact__first-row-showroom">
        <v-col cols="12" sm="6" class="pa-0">
          <template v-if="showRoomSingleton.cover.video == true">
            <video
              width="100%"
              class="Contact__img-showroom"
              
              :src="
                'https://www.cymcms.actstudio.xyz/storage/uploads/' +
                showRoomSingleton.cover.path
              "
              :autoplay="true"
              :muted="true"
              :playsinline="true"
              :controls="false"
              :loop="true"
            ></video>
          </template>
          <template v-else-if="showRoomSingleton.cover.image == true">
            <v-img
              width="100%"
              class="Contact__img"
              style="object-fit: cover"
              :src="
                'https://www.cymcms.actstudio.xyz/storage/uploads/' +
                showRoomSingleton.cover.path
              "
            ></v-img>
          </template>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <FormVirtual id="form-virtual"></FormVirtual>
        </v-col>
      </v-row>
      <v-row class="virtual__yellow-line ">
        <v-col cols="12">


        </v-col>
      </v-row>

      <v-row class="virtual__second-row">
        <v-col cols="12" sm="6" class="pa-0" order="1" order-sm="0">
          <div class="virtual__second-row--column">
            <span
              class="only-22 d-block bold montserrat-regular mb-9 mt-13 line-height-some-titles"
            >
              {{ showRoomSingleton.rightSpaceText }}
            </span>

            <v-row justify="center" no-gutters>
              <v-col cols="auto" class="border-bottom">
                <v-row justify="center">
                  <v-col cols="auto" class="text-center">
                    <img src="@/assets/icons/calendar.svg" width="30" />

                    <span class="d-block mb-2 continuos-texts-small-20"
                      >Date</span
                    >
                    <span class="d-block montserrat-regular"
                      >{{ showRoomSingleton.date }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center" no-gutters>
              <v-col cols="auto">
                <v-row justify="center">
                  <v-col cols="auto" class="text-center">
                    <img src="@/assets/icons/placeholder.svg" width="30" />

                    <div class="content-max-width">
                      <a
                        :href="showRoomSingleton.addressMaps"
                        target="_blank"
                        class="not-text-decoration"
                        ><span class="d-block mb-2 continuos-texts-small-20"
                          >Address</span
                        ></a
                      >

                      <a
                        :href="showRoomSingleton.addressMaps"
                        target="_blank"
                        class="not-text-decoration"
                        ><span class="continuos-texts-12 montserrat-regular">{{
                          showRoomSingleton.address
                        }}</span></a
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 text-center color-cream" order="0" order-sm="1">
          <v-img
            width="100%"
            height="100%"
            
            class="virtual__second-row--img"
            :src="
              'https://www.cymcms.actstudio.xyz/' +
              showRoomSingleton.rightImgCover.path
            "
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormVirtual from "../components/Virtual/FormVirtual";

export default {
  components: {
    FormVirtual,
  },
  data() {
    return {
      showRoomSingleton: {
        cover: {
          path: "",
        },
      },
      target: "#form-virtual",
    };
  },
  beforeCreate() {
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/VirtualShow?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.showRoomSingleton = data));
  },
  methods: {
    formatDate: function (date) {
      var regularDate = new Date(date * 1000);
      var months = [
        "Enero",
        "Febrero",
        "Marzo",
        "April",
        "Mayo",
        "Junio",
        "Julio",
        "Augosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Deciembre",
      ];
      var formatedDate =
        regularDate.getDate() +
        " " +
        months[regularDate.getMonth()] +
        " " +
        regularDate.getFullYear();
      return formatedDate;
    },
  },
};
</script>